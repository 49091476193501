import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Kindergeld() {

  const data = useStaticQuery(graphql`{
  kindergeld: file(relativePath: {eq: "kindergeld-show-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />

      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Webová aplikácia pre získanie prídavkov na dieťa v Nemecku - Kindergeld</h1>
              <p>Vytvorili sme aplikáciu pre matku aplikácií EasyForms, ktorá zjednodušuje ľuďom životy pri získavaní prídavkov na deti v Nemecku. Aplikácia používateľa prevedie jednoducho cez sériu otázok a na základe odpovedí vytvorí PDF na konkrétny úrad.</p>
            </div>
            <div className="col-12"><GatsbyImage
              image={data.kindergeld.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="0% 0%"
              alt="Web aplikácia" />  </div>
          </div>
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/doku" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
